import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ApprovalIcon from '@mui/icons-material/Approval';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
// import Swal from 'sweetalert2'
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import VideoCallIcon from '@mui/icons-material/VideoCall';
// import crypto from "crypto";

function MyVerticallyCenteredModal(props) {
  const [daters, setDateRs] = useState();
  const [timers, setTimeRs] = useState();
  const datehaldler = (event) => setDateRs(event.target.value)
  const timehaldler = (event) => setTimeRs(event.target.value)
  const updateappointment = async () => {
    if (daters == undefined) {
      // Swal.fire('Oops...', 'date field is required!', 'error')
    } else if (timers == undefined) {
      // Swal.fire('Oops...', 'time field is required!', 'error')
    } else {
      const id = document.getElementById('idtoupd').value
      const data = {
        "reschedule_date": daters,
        "reschedule_time": timers,
        "status": "Waiting for Client confirmation"
      }
      console.log(data)
      const apihandler = `https://api.lotusharvestec.com/api/updateAppointmentdataAPI/${id}`
      const settings = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      };
      try {
        const res = await fetch(apihandler, settings);
        const apidata = await res.json();
        window.location.reload()
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ left: "10%" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirm Appointment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label style={{ margin: "0 2%", fontSize: "14px" }} >Reschedule Date:</label>
        <input style={{ margin: "2%", width: "96%" }}
          id="daters"
          variant="outlined"
          type="date"
          className="form-control"
          onChange={datehaldler}
        />
        <input style={{ margin: "2%", width: "96%" }}
          id="idtoupd"
          variant="outlined"
          type="text"
          className="form-control"
          hidden
          value={props.data}
        />
        <label style={{ margin: "0 2%", fontSize: "14px" }} >Reschedule time:</label>
        <input type="time" id="timers" name="time" onChange={timehaldler}
          min="10:00" max="19:00" required style={{ margin: "2%", width: "96%" }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={updateappointment} type="submit" style={{ backgroundColor: "rgb(59, 173, 33)", border: "none" }}>
          Update
        </Button>
        <Button onClick={props.onHide} style={{ backgroundColor: "red", border: "none" }}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default function data() {
  const [dataf, setApiData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [desireid, setDesireId] = useState();
  let fdataobj = {};
  const fdataarray = [];
  const api = window.harestec_service + "getAppointmentdata";
  const fetchApiData = async (url) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(url, settings);
      const apidata = await res.json();
      const fdata = apidata.data;
      setApiData(fdata?.map((i) => i));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchApiData(api);
  }, []);

  //delete function

  const deletedata = async (id) => {
    setModalShow(true)
    setDesireId(id)
  }

  const cancelappoint = async (cancelid) => {
    const id = document.getElementById('idtoupd').value
    const data = {
      "status": "Rejected"
    }
    console.log(data)
    const apihandler = `https://api.lotusharvestec.com/api/updateAppointmentdataAPI/${cancelid}`
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetch(apihandler, settings);
      const apidata = await res.json();
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  }

  const startcall = async(id) => {
    const data = {
      "auth_key" : "cef41180-f881-11ed-851e-f23c9306bf9f",
      "source" : "Harvestec",
      "type" : "meeting_attendees",
      "channel" : "Harvestec ",
      "publisher_id" : id,
      "token" : "harvestec"
    }
    const settings = {
      method: "POST",
      body: JSON.stringify(data),
      headers:{
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization" : "Bearer b0c1aa21-22f1-11ee-851e-f23c9306bf9f"
      },
    };
    try {
        
      window.open(`https://video.proeffico.com`)
    } catch (error) {
      console.log(error);
    }
  }

  const editdata = (id) => {
    window.location.replace(window.urlmain + `edituser?type=users&id=${id}`);
  }

  dataf?.forEach((element, index) => {
    fdataobj = {
      Slno: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={index + 1} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      Name: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.username}
        </MDTypography>
      ),
      Email: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.email}
        </MDTypography>
      ),
      date: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.date}
        </MDTypography>
      ),
      time: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.starttime}
        </MDTypography>
      ),
      status: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {element.status}
        </MDTypography>
      ),
      daters: (
        <MDTypography component="a" href="#" variant="caption" color="danger" fontWeight="medium" style={{ color: "red" }}>
          {element.reschedule_date == null ? <span style={{ color: "red" }}>Pending</span> : <span style={{ color: "rgb(59, 173, 33)" }}>{element.reschedule_date}</span>}
        </MDTypography>
      ),
      timers: (
        <MDTypography component="a" href="#" variant="caption" color="danger" fontWeight="medium">
          {element.reschedule_start_time == null ? <span style={{ color: "red" }}>Pending</span> : <span style={{ color: "rgb(59, 173, 33)" }}>{element.reschedule_start_time}</span>}
        </MDTypography>
      ),
      action: (<>
        <MDTypography component="a" href="#" variant="caption" color="info" fontWeight="medium">
          <div>
            <span title="Approve the appointment" style={{ color: "#01DFD7", marginLeft: "5px" }}><ApprovalIcon onClick={(e) => { e.preventDefault(); deletedata(element.id); }} /></span>
            <span title="Reject the appointment" style={{ color: "red", marginLeft: "5px" }}><CancelScheduleSendIcon onClick={(e) => { e.preventDefault(); cancelappoint(element.id); }} /></span>
            <span title="Start Call" style={{ color: "red", marginLeft: "5px" }}><VideoCallIcon onClick={(e) => { e.preventDefault(); startcall(element.id); }} /></span>
          </div>
        </MDTypography>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={desireid}
        />
      </>
      ),
    };
    fdataarray.push(fdataobj);
  });
  return {
    columns: [
      { Header: "Sl.no", accessor: "Slno", align: "center" },
      { Header: "Name", accessor: "Name", align: "center" },
      { Header: "Email", accessor: "Email", align: "center" },
      { Header: "Customer Recom. Date", accessor: "date", align: "center" },
      { Header: "Customer Recom. Time", accessor: "time", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Reschedule Date", accessor: "daters", align: "center" },
      { Header: "Reschedule Time", accessor: "timers", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: fdataarray,
  };
}
